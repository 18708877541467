<template>
<div>
  <v-tabs v-model="tab" 
      background-color="#EFC00F"
      dark
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
  >
    <v-tabs-slider color="primary"></v-tabs-slider>
    <v-tab>
      Dados do Comprador
    </v-tab>
    <v-tab>
      Contato/Localização
    </v-tab>    
    <v-tab>
      Responsáveis
    </v-tab>
  </v-tabs>
  <v-tabs-items v-model="tab">
    <v-tab-item> <!-- dados -->
      <v-card flat>
        <v-card-text>        
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field label="* Razão Social"
                            dense
                            :rules="[
                                this.$validators.string.required,
                                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                                v => this.$validators.string.lessThanOrEquals(v, 200)
                            ]"
                            v-model.trim="value.razaoSocial"/>
            </v-col>  
            <v-col cols="12" md="6">
              <v-text-field label="* Nome Fantasia"
                            dense
                            :rules="[
                                this.$validators.string.required,
                                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                                v => this.$validators.string.lessThanOrEquals(v, 200)
                            ]"
                            v-model.trim="value.nomeFantasia"/>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="* CNPJ"
                            dense
                            placeholder="99.999.999/9999-99"
                            :rules="[
                              this.$validators.string.required,
                              this.$validators.string.cnpj
                            ]"
                            v-mask="'##.###.###/####-##'"
                            v-model.trim="value.cnpj"/>
            </v-col>
            <v-col cols="12" md="6">
              <search-box
                  label="* Atividade Econômica"
                  popup-label="Selecione um Atividade Econômica"
                  :id.sync="value.idAtividadeEconomica"
                  :descricao.sync="value.descricaoAtividadeEconomica"
                  :hidden-clear="true"
                  :value="atividadeEconomica"
                  :loader-fn="loadeAtividadeEconomica"
                  item-key="id"
                  item-text="descricao"
                  :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Atividade Econômica encontrado
                  </p>
                </template>
              </search-box>
            </v-col>  
            <v-col cols="12" md="12">
              <v-textarea 
                dense
                rows="6"
                label="* Descrição da Empresa"
                :rules="[
                    this.$validators.string.required,
                    v => this.$validators.string.greaterThanOrEquals(v, 1),
                    v => this.$validators.string.lessThanOrEquals(v, 4000),
                ]"
                v-model.trim="value.descricao"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>          
    </v-tab-item>
    <v-tab-item> <!-- Contato/Localização -->
      <v-card flat>
        <v-card-text>        
          <v-row>
            <v-col cols="12">
              <strong>Contato</strong>
              <v-divider/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="Email"
                            dense
                            :rules="[
                              this.$validators.string.email,
                              v => this.$validators.string.lessThanOrEquals(v, 50),
                          ]"
                          v-model.trim="value.email"/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="Telefone/Celular"
                            dense
                            v-mask="'(##) #####-####'"
                            v-model.trim="value.telefone"/>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="Site/Rede Social"
                            dense
                            v-model.trim="value.siteRede"/>
            </v-col>
            <v-col cols="12">
              <strong>Localização</strong>
              <v-divider/>
            </v-col>  
            <v-col cols="12" sm="3">
              <v-text-field label="* CEP"
                            dense
                            v-mask="'########'"
                            :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 8),
                              v => this.$validators.string.lessThanOrEquals(v, 8),
                            ]"
                            v-model.trim="value.cep"/>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="* Cidade"
                            dense
                            :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          :value="value.cidade"/>
            </v-col>
            <v-col cols="12" sm="3">
              <lookup-dominio label="* Estado"
                              :hidden-clear="true"
                              :value="value.estado"
                              :type="lookups.estado"
                              :rules="[
                                  this.$validators.string.required
                              ]"/>
            </v-col>    
            <v-col cols="12" sm="3">
              <v-text-field label="* Bairro"
                            dense
                            :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          v-model.trim="value.bairro"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea v-if="$vuetify.breakpoint.mobile"
                          dense
                          rows="3"
                          label="* Endereço"
                          :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          v-model.trim="value.endereco"/>
              <v-text-field v-else label="* Endereço"
                            dense
                            :rules="[
                              this.$validators.string.required,
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          v-model.trim="value.endereco"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea v-if="$vuetify.breakpoint.mobile"
                          dense
                          rows="3"
                          label="Complemento"
                          :rules="[
                              v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          v-model.trim="value.complemento"/>
              <v-text-field v-else label="Complemento"
                            dense
                            :rules="[
                                v => this.$validators.string.greaterThanOrEquals(v, 1),
                              v => this.$validators.string.lessThanOrEquals(v, 100),
                          ]"
                          v-model.trim="value.complemento"/>
            </v-col>            
          </v-row>
        </v-card-text>
      </v-card>          
    </v-tab-item>
    <v-tab-item> <!-- Responsáveis -->
      <v-card flat>
        <v-card-text>      
          <ListaContatos :tipoContato="'FOR'" :contatos="value.contatos"/>
        </v-card-text>
      </v-card>        
    </v-tab-item>
  </v-tabs-items>
</div>    
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import ListaContatos from "../common/ListaContatos";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import {buscaEnderecoViaCep} from '../../../api/correios';
import Vue from 'vue';
//PKs
import {findAll as findAllAtividadeEconomica} from "../../../api/atividadeEconomica";

export default {
  name: "CompradorForm",
  components: {SearchBox, LookupDominio, ListaContatos},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      tab: null,
      lookups: {
        estado: ItemDeDominio.COMBO_ESTADO, 
      },
      loading: false,
      error: null,
    }
  },
  computed: {
    atividadeEconomica(){
      if (this.value.idAtividadeEconomica == null) return null;
      return {
        id: this.value.idAtividadeEconomica,
        descricao: this.value.descricaoAtividadeEconomica
      }      
    },
  },
  watch: {
    'value.cep': {
      handler(newValue, oldValue) {
        this.buscaEndereco();
      }
    }
  },
  methods: {
    atualizarContatos(contatosAtualizados) {
      // console.log('atualizarContatos comprador',contatosAtualizados);
      this.value.contatos = contatosAtualizados;
    },
    async buscaEndereco(){
      if (this.value.cep !== "" && this.value.cep.length === 8){
        let endereco = await buscaEnderecoViaCep(this.value.cep);
        Vue.set(this.value,'cidade',endereco.localidade);
        Vue.set(this.value,'estado',endereco.uf);
        Vue.set(this.value,'bairro',endereco.bairro);
        Vue.set(this.value,'endereco',endereco.logradouro);
      }
    },
    loadeAtividadeEconomica(page, search) {
      return findAllAtividadeEconomica(page, {
        nome: search
      })
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>